

import videoBg from '../assets/VIDEOSIG.mp4'
import {motion as m} from "framer-motion";
import {fadeIn} from '../components/transitions'

import ne from '../images/neww.png';
import videoB from '../assets/SISTACMSIGAI.mp4';
import sat from '../images/Sathyabama Institute of Science and Technology.png';
import grp from '../images/grp-01.jpeg.jpg';


const Main = () => {
  const submitForm = async (e) => {
    e.preventDefault(); 
    const form = e.target;
    const formData = new FormData(form);
  
    console.log('Form data being sent:', Object.fromEntries(formData.entries()));
  
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyThoA0HoRYLNKygVqKF0IeU9rvPSVY2f1G6Q9bPVQtlkSLhoRIMdoTVrcPskDmsJw/exec",
        {
          method: "POST",
          body: formData
        }
      );
  
      console.log('Fetch response:', response);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Success:", data);
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Fetch error:", error);
      alert("Form submitted successfully!");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

 
  return (
    <>

    <div className='scroll-watcher'></div>
    
    
    <div className='main'>
        
        <video src={videoBg} autoPlay loop muted/>
       
        
    </div>
    <div className='About'>
                <div className='Aboutt'>
                    <m.div
                    variants={fadeIn("up",0.2)}
                     initial="hidden"
                     whileInView={"show"}
                     viewport={{once: false, amount: 0.7}}
                    className='aboutsec'>
                        <h2 className='about-heading'>ABOUT <span>SIST ACM SIGAI</span></h2>
                        <p className='about-paragraph'>Founded on the 25th of March 2024, SIST ACM SIGAI is the first SIGAI student chapter in Tamil Nadu. We are a community, paving the way for AI enthusiasts to come together to learn and innovate. At SIST ACM SIGAI student chapter, we are focused on the advancement of our society through the power of Artificial Intelligence. Whilst working with AI in computing, we are also keen on working with people of various interests to integrate AI with fields like healthcare, education and more. As a part of the world’s largest educational and scientific computing society, we want to use our resources and connections to help and support students and create a passion for artificial intelligence in them. Our mission is to ignite curiosity, foster collaboration, and drive innovation in the exciting world of Artificial Intelligence. We envision to be the heart of AI exploration, where students feel supported and equipped to face challenges and opportunities with the transformative capabilities of AI. Join us to get contented by turning your biggest passion of AI into reality using our best resources.</p>
                    </m.div>

                    <m.div 
                    variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}className='main-about'>
                        <video src={videoB} autoPlay loop controls muted />
                    </m.div>

                    <m.div
                    variants={fadeIn("up",0.4)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.5}}
                     className='mission'>
                        <h1><span>OUR</span> MISSION</h1>
                        <p className='mission-paragraph'>At SIST ACM SIGAI Student Chapter, we're on a mission to ignite curiosity, foster collaboration, and drive innovation in the exciting world of Artificial Intelligence (AI). Our aim is simple yet profound: to empower students to explore, understand, and leverage AI's potential for creating positive change in our world.</p>
                    </m.div>

                    <m.div 
                    variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                      className='image-container'>
                        <img src={ne} alt='LOGO REVEAL' />
                    </m.div>

                    <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}className='vision'>
                        <h1><span>OUR</span> VISION</h1>
                        <p className='mission-paragraph'>We envision a vibrant community where students from all backgrounds come together to learn, grow, and make a difference through AI. Our vision is to be the heart of AI exploration, where every student feels inspired, supported, and equipped to tackle the challenges and opportunities of tomorrow, ultimately contributing to a future empowered by the transformative capabilities of AI.</p>
                    </m.div>

                    <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    exit={"exit"}
                    viewport={{once: false, amount: 0.7}} className='image-container'>
                        <img src={sat} alt='SIST ACM SIGAI' />
                    </m.div>

                    <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}className='ideology'>
                        <h1><span>OUR</span> IDEOLOGY</h1>
                        <p className='mission-paragraph'>In our student chapter, we're not just about AI; we're about people—students who are eager to learn, explore, and make a difference. Drawing inspiration from the rich legacy of SIGAI, we've crafted a space that's more than just a gathering of minds; it's a home for those who dare to dream and do. Through a tapestry of events, workshops, and projects, we're weaving together an experience that's as diverse as the students we serve. From coding novices to seasoned enthusiasts, everyone has a place here—a seat at the table where ideas are born, connections are forged, and brilliance shines. But our journey doesn't stop at the borders of our campus. No, we're reaching out, extending our hand to communities far and wide, because we believe that AI isn't just about algorithms and data—it's about people and the world we live in. Through outreach programs, partnerships, and initiatives, we're harnessing the power of AI to drive positive change, one step at a time. Yet, in our quest for innovation, we never lose sight of what truly matters: integrity, transparency, and responsibility. Guided by these principles, we strive to be stewards of AI, ensuring that our exploration is not just groundbreaking but also ethical, equitable, and inclusive. So, if you're ready to embark on a journey of discovery, growth, and impact, welcome to SIST ACM SIGAI Student Chapter—where the future of AI is not just imagined, but created, together.</p>
                    </m.div>

                    <m.div variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='image-container'>
                        <img src={grp} alt='OUR CORE UNIT' />
                    </m.div>
            </div>
            <div className='ba'>
      <div
      variants={fadeIn("up",0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}} className='wrapper'>
       
       
        <div className='contactus'>
        <div className='contitle'>
          <h2>CONNECT WITH US</h2>
       
        </div>
        <div className='boxy'>
          <div className='contact form'>
            <h3>Send a message</h3>
            <form className="form" onSubmit={submitForm}>
              <div className='formBx'>
                <div className='row50'>
                  <div className='inputbx'>
                    <span>First Name</span>
                    <input type='text'  name='Firstname' className='Firstname' id='Firstname'></input>
                  </div>
                  <div className='inputbx'>
                    <span>Last Name</span>
                    <input type='text' name='Lastname' className='Lastname' id='Lastname'></input>
                  </div>

                </div>
                <div className='row50'>
                  <div className='inputbx'>
                    <span>Email</span>
                    <input type='text' placeholder='example@email.com' name='Email'></input>
                  </div>
                  <div className='inputbx'>
                    <span>Mobile</span>
                    <input type='text' placeholder='+91 **********' name='Mobile'></input>
                  </div>
                  

                </div>
                <div className='row100'>
                  <div className='inputbx'>
                    <span>Message</span>
                    <textarea placeholder='Write your message here..' name='Message'></textarea>
                  </div>
                  
                  

                </div>
                <div className='row100'>
                  <div className='inputbx'>
                  <input type='submit' value='SEND'></input>
                    
                  </div>
                  
                  

                </div>
              </div>
            </form>
          </div>
          
          <div className='contact info'>
            <h3>Contact Info</h3>
            <div className='infobx'>
            <div>
    <span><ion-icon name="location-outline"></ion-icon></span>
    <p>
        <a href='https://www.sathyabama.ac.in/'>SATHYABAMA INSTITUTE OF SCIENCE AND TECHNOLOGY</a><br />
        SEMMENCHERI, CHENNAI, INDIA
    </p>
</div>

              <div>
    <span><ion-icon name="mail-outline"></ion-icon></span>
    <a href='https://mail.google.com/mail/?view=cm&fs=1&to=sist.sigai@gmail.com'>sist.sigai@gmail.com</a>
</div>

<div>
    <span><ion-icon name="call-outline"></ion-icon></span>
    <a href='tel:+916383594324'>+91 6383594324</a>
</div>

              <div className='social'>
            
             <a href={'https://x.com/sist_sigai'}><i class="fab fa-twitter"></i></a>
             <a href={'https://www.instagram.com/sist_sigai?igsh=bzBjc3Jmam85NTdn'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/company/sist-acm-sigai-student-chapter/'}><i class="fab fa-linkedin"></i></a>
             </div>

        
            </div>
          </div>
          <div className='contact map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.5412320274245!2d80.22350167642874!3d12.87288088743351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525b8c90befe2b%3A0x170ab8b5b21bb530!2sSathyabama%20Institute%20of%20Science%20and%20Technology!5e0!3m2!1sen!2sin!4v1710506289648!5m2!1sen!2sin"
        width="600" height="450" style={{border: '0', allowfullscreen: '', loading: 'lazy', referrerpolicy: 'no-referrer-when-downgrade'}}></iframe>

          </div>
        </div>
      </div>
      <div className='heading-line'></div>
      <div className='cpoy-cont'>
      
 

  <div className='Copyrights'>
    <h2>&copy; 2025 SIST ACM SIGAI STUDENT CHAPTER</h2>
    <p>Website developed by ADITYA SAI TEJA B</p>
    <p>Designed by MANISRI VENKATESH</p>
    <p>Backend development: BHUVANESH and DEVENDRA REDDY</p>
  </div>
</div>

</div>
                </div>

      

     </div>
            <button className='back-to-top' onClick={scrollToTop}>
                <i className="fa-solid fa-arrow-up"></i>
            </button>

    
   
    
     

            {/* </m.div> */}
    </>
  )



}

export default Main;
import React, { useState } from 'react';
import {motion as m} from "framer-motion";
import Tilt from 'react-vanilla-tilt'
import videoBg from '../assets/VIDEOSIG.mp4'
import images from '../images/acm-sigai-logo-dark.svg'
import img from '../images/Anu Barathi_photo.jpg'
import imges from '../images/sathyabamamaam-removebg-preview.png'
import imgas from '../images/HOD maam.jpg'
import chair from '../images/MUK06822-01.jpeg'
import fcu from '../images/bharath1.jpg'
import tre from '../images/DHEEKSITHA.jpg'
import des from '../images/SIVA1.jpg'
import wea from '../images/adi2.jpg'
import mani from '../images/venkatesh.jpg'
import son from '../images/SONALI1.jpg'
import ava from '../images/janllyn avantikha.jpeg'
import meg from '../images/meghana tanikella.jpg'
import har from '../images/harii.jpg'
import ram from '../images/ram prasath.jpg'
import unk from '../images/20240313_222959.jpg'
import uno from '../images/BATT.jpg'
import unu from '../images/vedha-varshini.jpg'
import fah from '../images/faheems.jpg'
import bhu from '../images/bhuvan.jpg'
import deva from '../images/deva.jpg'
import Sect from '../images/SSECT.jpg'
import Navya from '../images/img1 - navya sree.jpg'
import Likil from '../images/- likhil madhav.jpeg'
import Aafiya from '../images/Aafiya Afsheen.jpg'
import Hariharan from '../images/HariharanV_CSE-DS - Hari Haran.JPG'
import Bargav from '../images/IMG_0299 - Bhargav krishna Gali.jpeg'
import Dhruva from '../images/Dhruva Kumar.jpg';
import Sanjesh from '../images/Sanjesh Portrait - Sanjesh R.G.jpg';
import SaiKrishna from '../images/IMG20240609111221 - sai krishna kaushik kanduri.jpg';
import GeoSovi from '../images/Geo Sovi.jpg';
import Mokshini from '../images/photo - Mokshini Yadav.jpg'
import Subin from '../images/IMG_9461 - SubinTony.jpeg';
import Dharsan from '../images/IMG_5936_Original - Dharsan .L.jpeg';
import Vishal from '../images/passport photo - Tk Vishal.jpg';
import Ukenthiran from '../images/ukethiran.png';
import Lokesh from '../images/PSX_20241212_092333 - Lokesh.jpg';
import Shahul from '../images/1000004341 - Shahul Hameed.jpeg';
import Trimurthulu from '../images/123 (1) - trimurthulu nakka.jpg';
import hadlinsPrice from '../images/IMG-20240719-WA0004(1) - Hadlins Price.jpg';
import harismitha from '../images/PSX_20240728_201137 - Harismitha.jpg';
import hema from '../images/42614044 Hema - Hema Priyanka.jpg';
import jangalaSriVaishnavi from '../images/pp - Sri Vaishnavi Jangala.jpg';
import joyclynImmanuel from '../images/1726494813000_plus - Joyclyn Immanuel.jpg';
import katepalliPavaniPriya from '../images/IMG_1155 - Pavani Katepalli.jpg';
import nirbhay from '../images/SAVE_20240918_090430-min - Nirbhay Shanker.jpg';
import shreyas from '../images/IMG_20240907_150019_639 - SHREYAS S.jpg';
import Varun from '../images/tedx_submitted_photo (1) - Sai Varun Chandrashekar.png'
import arva from '../images/IMG_20231125_141626 - Aravind Reddy.jpg'
import {fadeIns} from '../components/Transitionss'

const About = () => {
  
  const [selectedUnit, setSelectedUnit] = useState('volunteers');
  const handleDropdownChange1 = (event) => {
    setSelectedUnit(event.target.value);
    scrollToTop();
  };
  // Data for cards
  const volunteersData = [
    {
      id: 1,
      name: "Joyclyn Immanuel J",
      designation: "Volunteer Unit",
      img: joyclynImmanuel,
      className: "joyclyn",
    },
    {
      id: 2,
      name: "H.HADLINS PRICE",
      designation: "Volunteer Unit",
      img: hadlinsPrice,
      className: "hadlins-price",
    },
    {
      id: 3,
      name: "Harismitha",
      designation: "Volunteer Unit",
      img: harismitha,
      className: "harismitha",
    },
    {
      id: 4,
      name: "Hema S",
      designation: "Volunteer Unit",
      img: hema,
      className: "hema-s",
    },
    {
      id: 5,
      name: "Jangala Sri Vaishnavi",
      designation: "Volunteer Unit",
      img: jangalaSriVaishnavi,
      className: "jangala-sri-vaishnavi",
    },
    {
      id: 6,
      name: "Katepalli PavaniPriya",
      designation: "Volunteer Unit",
      img: katepalliPavaniPriya,
      className: "katepalli-pavani-priya",
    },
    {
      id: 7,
      name: "Nirbhay",
      designation: "Volunteer Unit",
      img: nirbhay,
      className: "nirbhay",
    },
    {
      id: 8,
      name: "Shreyas S",
      designation: "Volunteer Unit",
      img: shreyas,
      className: "shreyas-s",
    },
  ];
  
  const mediaUnitData = [
    { 
      id: 1, 
      name: "Subin VM", 
      designation: "Media Unit", 
      img: Subin, 
      className: "subin-class", 
    },
    { 
      id: 2, 
      name: "Dharsan L", 
      designation: "Media Unit", 
      img: Dharsan, 
      className: "dharsan-class", 
    },
    { 
      id: 3, 
      name: "T K Vishal", 
      designation: "Media Unit", 
      img: Vishal, 
      className: "vishal-class", 
    },
    { 
      id: 4, 
      name: "Ukenthiran A", 
      designation: "Media Unit", 
      img: Ukenthiran, 
      className: "ukenthiran-class", 
    },
    { 
      id: 5, 
      name: "Lokesh R", 
      designation: "Media Unit", 
      img: Lokesh, 
      className: "lokesh-class", 
    },
    { 
      id: 6, 
      name: "Shahul Hameed A", 
      designation: "Media Unit", 
      img: Shahul, 
      className: "shahul-class", 
    },
    { 
      id: 7, 
      name: "Nakka Purna Durga Trimurthulu", 
      designation: "Media Unit", 
      img: Trimurthulu, 
      className: "trimurthulu-class", 
    }
  ];
  
  const researchUnitData = [
    { 
      id: 1, 
      name: "Konduru Navya Sree", 
      designation: "Research Unit", 
      img: Navya, 
      className: "Navya", 
    },
    { 
      id: 2, 
      name: "MANDA LIKHIL MADHAV", 
      designation: "Research Unit", 
      img: Likil, 
      className: "likil-class", 
    },
    { 
      id: 3, 
      name: "Aafiya Afsheen N", 
      designation: "Research Unit", 
      img: Aafiya,
      className: "aafiya-class",
    },
    { 
      id: 4, 
      name: "Hariharan V", 
      designation: "Research Unit", 
      img: Hariharan,
      className: "hariharan-class",
    },
    { 
      id: 5, 
      name: "Bargav Krishna Gali", 
      designation: "Research Unit", 
      img: Bargav,
      className: "bargav-class",
    },
    { 
      id: 6, 
      name: "Dumpa Dhruva Kumar", 
      designation: "Research Unit", 
      img: Dhruva,
      className: "dhruva-class",
    },
    { 
      id: 7, 
      name: "Sanjesh RG", 
      designation: "Research Unit", 
      img: Sanjesh,
      className: "sanjesh-class",
    },
    { 
      id: 8, 
      name: "Kanduri Sai Krishna Kaushik", 
      designation: "Research Unit", 
      img: SaiKrishna,
      className: "saikrishna-class",
    },
    { 
      id: 9, 
      name: "Geo Sovi", 
      designation: "Research Unit", 
      img: GeoSovi,
      className: "geosovi-class",
    },
    { 
      id: 10, 
      name: "Annangi Mokshini Yadav", 
      designation: "Research Unit", 
      img: Mokshini,
      className: "mokshini-class",
    }

  ];
  
  // Function to handle dropdown change
  const handleDropdownChange = (event) => {
    setSelectedUnit(event.target.value);
  };
  // Get the data based on the selected unit
  const getCardsData = () => {
    switch (selectedUnit) {
      case 'media':
        return mediaUnitData;
      case 'research':
        return researchUnitData;
      default:
        return volunteersData;
    }
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
    return (
        <>
        {/* <m.div 
  initial={{ y: "100%"}} 
  animate={{ y: "0%"}}
  exit={{ opacity: 1}}
  transition={{duration: 0.75, ease: "easeOut"}}
  > */}
          <div
       
           className='about1' id='about'>
      
      <m.h1 variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}>EXPLORE ACM SIGAI!!</m.h1>
        <div className='content1'>
          <div className='img'>
          <img src={images} alt='img' />
          </div>
      
          <div className='wrapper1'>
          <h3>The scope of SIGAI, ACM's Special Interest Group on Artificial Intelligence, 
          consists of the study of intelligence and its realization in computer systems. SIGAI's mission is to promote and support AI-related conferences.<break></break> Members receive reduced registration rates to all affiliated conferences. Members also receive proceedings from the major SIGAI-sponsored conferences.SIGAI publishes a quarterly newsletter, AI Matters,
          with ideas and announcements of interest to the AI community.</h3>
             <h3>ACM SIGAI is the Association for Computing Machinery's Special Interest Group on Artificial Intelligence (AI),<break></break>
               an interdisciplinary group of academic and industrial researchers, practitioners,<break></break> software developers, end users, and students who work together to promote and support the growth and application of AI principles and techniques throughout computing. SIGAI is one of the oldest special interest groups in the ACM. SIGAI, previously called SIGART, <break></break>started in 1966, publishing the SIGART Newsletter that
               later became the SIGART Bulletin and Intelligence Magazine.
              <br/> On January 10, 1947, at the Symposium on Large-Scale Digital Calculating Machinery at the Harvard computation Laboratory, Professor Samuel H. Caldwell of Massachusetts Institute of Technology spoke of the need for an association of those interested in computing machinery, and of the need for communication between them.
               After making some inquiries during May and June, we believe there is ample interest to start an informal association of many of those interested in the new machinery for computing and reasoning. Since there has to be a beginning, we are acting as a temporary committee to start such an association.</h3>
               <div className='button1'>
                <a href={'https://sigai.acm.org/main/'}>Explore more</a>
               </div>
               
               
          </div>
          
        </div>
      
      </div>
  
                <div className='bd'>  
  <div className='wrapper'>

    
    <m.div variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}} className='title-core'>
          <h4><span>MEET SIST SIGAI</span> </h4>

        </m.div>
        <div className='card-container1'>
          <Tilt id="tilt-card" 
           options={{ scale: 2,
            
          }}
           style={{
               width: 420,
               
   
              
               
              
           }}>
        <m.div
        variants={fadeIns("up",0.2)}
        initial="hidden1"
        whileInView={"show1"}
        viewport={{once: false, amount: 0.7}} className='cards'>
          <div className='imbx'>
          <img src={chair} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>GOWTHAM S<br /><span>CHAIRPERSON</span></h3>
            </div>
            <div className='social'>
             
             
             <a href={'https://www.instagram.com/_iam_._gowtham_/'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/gowtham--s/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>
        </m.div>
             </Tilt>
             <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div 
        variants={fadeIns("up",0.2)}
        initial="hidden1"
        whileInView={"show1"}
        viewport={{once: false, amount: 0.7}} className='cards'>
          <div className='imbx13'>
          <img src={fcu} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>KODIDASU BHARATH<br /><span>VICE CHAIRPERSON</span></h3>
            </div>
            <div className='social'>
             
             <a href={'https://www.instagram.com/b.h.a.r.a.t.h_1410/'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/bharathkodidasu/'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>
        </m.div>
          </Tilt>
          
             <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div 
        variants={fadeIns("up",0.2)}
        initial="hidden1"
        whileInView={"show1"}
        viewport={{once: false, amount: 0.7}} className='cards'>
          <div className='imbx2'>
          <img src={tre} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>DEEKSHITHA<br /><span>TREASURER</span></h3>
            </div>
            <div className='social'>
             
             <a href={'https://twitter.com/izz_deek_?t=5fATezGBzyIf8bjP_-V3aA&s=08'}><i class="fab fa-twitter"></i></a>
             <a href={'#'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/deekshitha-uppu-571b122b8/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        
        

        </Tilt>

        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div
        variants={fadeIns("up",0.2)}
        initial="hidden1"
        whileInView={"show1"}
        viewport={{once: false, amount: 0.7}} className='cards'>
          <div className='imbx2'>
          <img src={Sect} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>SRI SOUNDHARYA<br /><span>SECRETARY</span></h3>
            </div>
            <div className='social'>
             
             
             <a href={'#'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/deekshitha-uppu-571b122b8/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        
        

        </Tilt>
        </div>
        <m.div variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}} className='title-core'>
          <h4><span>OUR CORE UNIT</span> </h4>

        </m.div>
        <div className='card-container1'>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("right",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx3'>
          <img src={des} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3> SIVA KRISHNA ADIMULAM<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
             
             
             <a href={'https://www.instagram.com/literally.vibe/'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/siva-krishna-adimulam-08100422b/'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx1'>
          <img src={wea} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>ADITYA SAI TEJA B<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
             
             <a href={'https://www.instagram.com/_aditya_teja?igsh=MWpocDdpdG8yMmpvOA=='}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/aditya-sai-teja-b-013a98251?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
           <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("left",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx9'>
          <img src={mani} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>D MANISRI VENKATESH<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
           
             <a href={'https://www.instagram.com/manisrivenkatesh?igsh=MXZ2NHQ5dHRua3F4Yg=='}><i class="fab fa-instagram"></i></a>
             <a href={'http://linkedin.com/in/manisri-venkatesh-93021b299/'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>

        </div>
        <div className='card-container1'>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("right",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx16'>
          <img src={deva} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>V DEVENDRA REDDY<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
           
             <a href={'https://www.instagram.com/dev_is_not_a_name?igsh=bXN6bTU4cGJyYnRi'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/devendra-reddy-344650251/'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx'>
          <img src={bhu} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>D.V BHUVANESH<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
             
             <a href={'https://www.instagram.com/bhuvan._.rebel/'}><i class="fab fa-instagram"></i></a>
             <a href={'https://linkedin.com/in/bhuvan-bhu1'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("left",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx5'>
          <img src={son} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>SUSHREE SONALI PATRA<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
           
             <a href={'https://www.linkedin.com/in/sushree-sonali-patra-734206289?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>
          
        </m.div>
          </Tilt>
       
        </div>
        <div className='card-container1'>
       
          <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("right",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx6'>
          <img src={ava} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>jANLLYN AVANTIKHA<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
             
             <a href={'https://www.instagram.com/janllynavantikha_?igsh=MXRjdDc4bGIzdzUyNw%3D%3D&utm_source=qr'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/janllyn-avantikha-90268726a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx21'>
          <img src={meg} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>TANIKELLA LAKSHMI PHANI MEGHANA<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
      
             <a href={'https://www.instagram.com/tanikellameghana?igsh=MXcxcDIxOHp1cGZ5cw=='}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/meghana-tanikella-5103482b0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        
        
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("left",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx8'>
          <img src={uno} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>BATTINA VAISHNAVI<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
            
             <a href={'https://www.instagram.com/vaishnavi_battina_?igsh=NWV1eDdhaWcyZzgz'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/battina-vaishnavi-5743652b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>

        </div>
        <div className='card-container1'>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("right",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx7'>
          <img src={har} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>B HARITHREENATH<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
            
             <a href={'https://www.instagram.com/itz_hari_13_?igsh=dTludnhiZms1bmht'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/bellamkonda-harithreenath-906665287?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("up",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx30'>
          <img src={Varun} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>SAI VARUN CHANDRASHEKAR<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
          
             <a href={'https://www.instagram.com/sai_varun04?igsh=cWtieW5hMjJjcmg5'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/sai-varun-chandrashekar-93b8bb273?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("left",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx12'>
          <img src={fah} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>FAHEEM MOHAMED RAFI<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
             
             <a href={'https://www.instagram.com/faheem._.18?igsh=cGwwcjVrYTl5anp1'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/faheem-mohamed-rafi-3ba4a1250?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        
        
        </Tilt>
        </div>
        <div className='card-container1'>
        
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("right",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx11'>
          <img src={unu} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>VEDHAVARSHINI V<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
            
             <a href={'https://www.instagram.com/vivi04_v?igsh=M2dwYW4wcWM4OXZr'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/vedha-varshini-vijay-ananth-a83918242?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        
        <m.div variants={fadeIns("left",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx'>
          <img src={ram} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>RAM PRASATH.R<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
        
             <a href={'https://www.instagram.com/__._nyctophile_._?igsh=eGdlencycDI2M3Nu'}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/ram-prasath-3309b4282?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        
        
        </Tilt>
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        
        <m.div variants={fadeIns("left",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx2'>
          <img src={unk} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>R. NIHARIKA<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
        
             <a href={'https://www.instagram.com/_niha_sri_217_?igsh=MXU0bnRicnE4bzhhaQ=='}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/niharika-ramayanam-5353642b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        
        
        </Tilt>
        </div>
        <div className='card-container1'>
        
        <Tilt id="tilt-card"
           options={{ scale: 2 }}
           style={{
               width: 420,
              
               
              
           }}> 
        <m.div variants={fadeIns("right",0.2)}
                    initial="hidden1"
                    whileInView={"show1"}
                    viewport={{once: false, amount: 0.7}}className='cards'>
          <div className='imbx31'>
          <img src={arva} alt='img'></img>

          </div>
          <div className='contents'>
            <div className='contentbx'>
              <h3>GALI ARAVIND KUMAR REDDY<br /><span>C0RE TEAM MEMBER</span></h3>
            </div>
            <div className='social'>
            
             <a href={'https://www.instagram.com/mr.aravind_._?igsh=MWsyeG9rMXR3MThzNw=='}><i class="fab fa-instagram"></i></a>
             <a href={'https://www.linkedin.com/in/aravind-reddy-bb536b244?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'}><i class="fab fa-linkedin"></i></a>
             </div>
          </div>

        </m.div>
        </Tilt>
        
        </div>
        <m.div 
        variants={fadeIns("up",0.2)}
        initial="hidden1"
        whileInView={"show1"}
        viewport={{once: false, amount: 0.7}} className='title'>
      <h4>FACULTY CONVENER</h4>
    </m.div>

    <div className='card-container1'>
   
      <m.div 
      variants={fadeIns("up",0.2)}
      initial="hidden1"
      whileInView={"show1"}
      viewport={{once: false, amount: 0.7}}
      className='cards1'>
        <div className='imbx14'>
          <img src={imgas} alt='img' />
        </div>
        <div className='contents'>
          <div className='contentbx'>
            <h3>DR.VIGNESHWARI S<br /><span>HOD CSE</span><br/><span>SIGAI MEMBER ID: 1089768</span></h3>
          </div>
          <p></p>
        </div>
      </m.div>
      </div>
      </div>
      <div className='wrapper'>
    <m.div 
    variants={fadeIns("up",0.2)}
    initial="hidden1"
    whileInView={"show1"}
    viewport={{once: false, amount: 0.7}}className='title-co'>
      <h4>FACULTY COORDINATORS</h4>
    </m.div>

    <div className='card-container1'>
      <m.div
      variants={fadeIns("right",0.2)}
      initial="hidden1"
      whileInView={"show1"}
      viewport={{once: false, amount: 0.7}} className='cards1'>
        <div className='imbx1'>
          <img src={imges} alt='img' />
        </div>
        <div className='contents'>
          <div className='contentbx'>
            <h3>DR.SATHYABAMA R<br /><span>ASSOCIATE PROFESSOR</span></h3>
          </div>
         
        </div>
      </m.div>

      <m.div
      variants={fadeIns("left",0.2)}
      initial="hidden1"
      whileInView={"show1"}
      viewport={{once: false, amount: 0.7}} className='cards1'>
        <div className='imbx2'>
          <img src={img} alt='img' />
        </div>
        <div className='contents'>
          <div className='contentbx'>
            <h3>DR. ANUBHARATHI<br /><span>ASSOCIATE PROFESSOR</span></h3>
          </div>
          
        </div>
      </m.div>
    </div>

  </div>
</div>

     <button className='back-to-top1' onClick={scrollToTop}>
        <i className="fa-solid fa-arrow-up"></i>
      </button>
      <div className='Unit'>
      <div className="dropdown-container">
        {/* Dropdown */}
        <select onChange={handleDropdownChange} value={selectedUnit} style={{ float: 'right' }}>
          <option value="volunteers">Volunteers</option>
          <option value="media">Media Unit</option>
          <option value="research">Research Unit</option>
        </select>
      </div>
      {/* <m.div
        variants={fadeIns("up", 0.2)}
        initial="hidden1"
        whileInView={"show1"}
        viewport={{ once: false, amount: 0.7 }}
        className="title-co"
      >
        <h4>{selectedUnit === "volunteers"
          ? "Volunteers"
          : selectedUnit === "media"
          ? "Media Unit"
          : "Research Unit"}</h4>
      </m.div> */}
    <div className='wrapper'>
      <div className="card-container1">
        {getCardsData().map((card) => (
            <m.div
            key={card.id}
            variants={fadeIns("left", 0.2)}
            initial="hidden1"
            whileInView="show1"
            viewport={{ once: false, amount: 0.7 }}
            className="cards1"
          >
            <div className={card.className}>
              <img src={card.img} alt="img" />
            </div>
            <div className="contents">
              <div className="contentbx">
                <h3>{card.name} <br /><span>{card.designation}</span></h3>
                
              </div>
              
            </div>
          </m.div>
          
        ))}
      </div>
    </div>
    </div>
      {/* </m.div> */}
        </>
    );
}

export default About;
